<template>
  <div class="blocks-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row
            type="flex"
            justify="space-between"
            :gutter="16"
            :style="{ marginBottom: '15px' }"
          >
            <!-- Title -->
            <a-col :xs="24" :md="12">
              <h2 class="form-title">
                <a-icon :type="isEdit ? 'edit' : 'form'" class="form-icon" />
                <span>{{
                  isEdit
                    ? `${labels.form.titleView} número: ${form.code || ""}`
                    : labels.form.titleProcess
                }}</span>
              </h2>
            </a-col>
            <!-- Actions -->
            <a-col :xs="24" :md="12">
              <a-row
                type="flex"
                justify="end"
                :gutter="16"
                :style="{
                  alignItems: 'flex-end',
                  marginBottom: '0px',
                }"
              >
                <a-col>
                  <a-button-group>
                    <a-button
                      :type="isEdit ? 'primary' : 'danger'"
                      ghost
                      @click="handleCancel"
                      icon="arrow-left"
                    >
                      <span>{{ labels.form.goBack }}</span>
                    </a-button>
                    <a-button
                      v-if="isEdit && canDelete"
                      type="danger"
                      @click="confirmDelete"
                    >
                      <span>{{ labels.form.deleteBlock }}</span>
                    </a-button>
                    <a-button
                      v-if="!isMobile && canEdit"
                      type="primary"
                      ghost
                      :loading="submitting"
                      @click="validate().then(() => handleSubmit(true))"
                    >
                      <span>{{ labels.form.saveAndClose }}</span>
                    </a-button>
                    <a-button
                      v-if="canEdit"
                      type="primary"
                      :loading="submitting"
                      @click="validate().then(handleSubmit)"
                    >
                      <span>{{ labels.form.save }}</span>
                    </a-button>
                    <a-dropdown :trigger="['click']" v-if="isMobile && canEdit">
                      <a-menu slot="overlay">
                        <a-menu-item
                          key="1"
                          @click="validate().then(() => handleSubmit(true))"
                          >{{ labels.form.saveAndClose }}</a-menu-item
                        >
                      </a-menu>
                      <a-button type="primary" ghost icon="more" />
                    </a-dropdown>
                  </a-button-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center">
            <a-col :span="24">
              <a-card>
                <a-row type="flex" justify="start">
                  <a-col :xs="24" :sm="24" :md="12">
                    <a-row type="flex" justify="space-between" :gutter="24">
                      <a-col :xs="24" :sm="24" :md="12">
                        <Input
                          v-model="form.plays"
                          :vid="labels.form.plays.label"
                          :label="labels.form.plays.label"
                          :name="labels.form.plays.label"
                          :formitem="{ label: labels.form.plays.label }"
                          :placeholder="labels.form.plays.placeholder"
                          v-themask="['##', '##-##', '##-##-##']"
                          rules="required"
                          :readOnly="!canEdit"
                        />
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="12">
                        <SelectValidation
                          v-model="form.block_type"
                          :placeholder="labels.form.blockType.placeholder"
                          :label="labels.form.blockType.label"
                          :dataSource="blockTypes"
                          :allowClear="true"
                          rules="required"
                          :disabled="!canEdit"
                        />
                      </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-between" :gutter="24">
                      <a-col :xs="24" :sm="24" :md="12">
                        <DatePicker
                          v-model="form.start_date"
                          :show-time="true"
                          :vid="labels.form.startDate.label"
                          :name="labels.form.startDate.label"
                          :formitem="{ label: labels.form.startDate.label }"
                          :placeholder="labels.form.startDate.placeholder"
                          :format="dateFormat"
                          rules="required"
                          :disabled="!canEdit"
                        />
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="12">
                        <DatePicker
                          v-model="form.end_date"
                          :show-time="true"
                          :vid="labels.form.endDate.label"
                          :name="labels.form.endDate.label"
                          :formitem="{ label: labels.form.endDate.label }"
                          :placeholder="labels.form.endDate.placeholder"
                          :format="dateFormat"
                          rules="required"
                          :disabled="!canEdit"
                        />
                      </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-between" :gutter="24">
                      <a-col :xs="24" :sm="24" :md="24">
                        <SelectPagination
                          v-model="form.lotteries"
                          :fetchService="fetchLotteries"
                          :placeholder="labels.form.lotteries.placeholder"
                          :labelKey="'name'"
                          :valueKey="'id'"
                          :label="labels.form.lotteries.label"
                          :disabledDefaultFetchOptions="false"
                          :allowClear="true"
                          mode="multiple"
                          :disabled="!canEdit"
                        />
                      </a-col>
                      <a-col
                        :xs="24"
                        :sm="24"
                        :md="24"
                        v-if="form.block_type === 'limit_amount'"
                      >
                        <Input
                          v-model="form.amount"
                          type="number"
                          :vid="labels.form.amount.label"
                          :label="labels.form.amount.label"
                          :name="labels.form.amount.label"
                          :formitem="{ label: labels.form.amount.label }"
                          :placeholder="labels.form.amount.placeholder"
                          :min="1"
                          :rules="'required'"
                          :disabled="!canEdit"
                        />
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import Input from "@/components/core/VeeValidateForm/Input.vue";
import DatePicker from "@/components/core/VeeValidateForm/DatePicker.vue";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import SelectValidation from "@/components/core/VeeValidateForm/Select.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import moment from "moment";
import router from "@/router";
import * as actions from "@/utils/actions";
import { mask } from "vue-the-mask";
import { adminPageTitle, hasRoles, roles } from "../../../utils/utils";

const initialFormState = {
  plays: "",
  block_type: "block_play",
  start_date: moment().startOf("day"),
  end_date: moment().endOf("day"),
  lotteries: [],
  amount: 0,
};

export default {
  name: "BlockForm",
  directives: { themask: mask },
  components: {
    ValidationObserver,
    Input,
    DatePicker,
    SelectPagination,
    SelectValidation,
  },
  metaInfo: {
    title: adminPageTitle(labels.blocks.title),
    titleTemplate: () => {
      return `${adminPageTitle()} - ${
        router.currentRoute.name.includes("view")
          ? labels.blocks.pageTitleViewing
          : labels.blocks.pageTitleCreate
      }`;
    },
  },
  data() {
    return {
      labels: labels.blocks,
      loading: false,
      submitting: false,
      type: undefined,
      form: _.cloneDeep(initialFormState),
      blockTypes: [
        {
          label: "Bloqueo de jugada",
          value: "block_play",
        },
        {
          label: "Limite de jugada",
          value: "limit_amount",
        },
      ],
    };
  },
  async created() {
    this.resetForm();
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  computed: {
    ...mapState("blocks", ["block"]),
    ...mapState("layout", ["screenWidth"]),
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    isEdit() {
      return !!this.$route.params?.id;
    },
    canEdit() {
      const { accessRoles } = this.getLoggedUser;
      return (
        !this.isEdit || hasRoles(accessRoles, [roles.admin.ROLE_BLOCKS_UPDATE])
      );
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_BLOCKS_DELETE]);
    },
    isPayment() {
      return this.form?.type?.name === "Pago";
    },
    dateFormat() {
      return "DD/MM/YYYY hh:mm:ss";
    },
    serverDateFormat() {
      return "YYYY-MM-DD HH:mm:ss";
    },
    money() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      };
    },
    isMobile() {
      return this.screenWidth < 768;
    },
  },
  methods: {
    ...mapActions("blocks", [
      "fetchBlock",
      "createBlock",
      "updateBlock",
      "deleteBlock",
      "resetForm",
    ]),
    ...mapActions("lotteries", ["fetchLotteries"]),
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchBlock(this.$route.params.id);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
        this.$router.push({ name: "admin.blocks" });
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(closeAfterSave = false) {
      if (this.submitting) return;
      this.submitting = true;
      let response = null;
      let editting = this.isEdit;
      try {
        const formData = _.cloneDeep(this.form);
        formData.start_date = moment(formData.start_date).format(
          this.serverDateFormat
        );
        formData.end_date = moment(formData.end_date).format(
          this.serverDateFormat
        );

        response = editting
          ? await this.updateBlock(formData)
          : await this.createBlock(formData);

        if (response) this.$message.success(response.message);
        if (closeAfterSave) {
          this.$router.push({ name: "admin.blocks" });
        } else {
          if (!editting) {
            this.$router.push({
              name: "admin.blocks.edit",
              params: { id: response.data.id },
            });
          }
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: "admin.blocks" });
    },
    confirmDelete() {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteBlock(this.form.id);
            this.$store.commit(`blocks/${actions.GET_BLOCK}`, null);
            this.$message.success(this.labels.deletedSuccess);
            this.$router.push({ name: "admin.blocks" });
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.deletedError
            );
          }
        },
      });
    },
  },
  watch: {
    "$route.params.id"(value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData();
      }
    },
    block: {
      handler(block) {
        Object.assign(
          this.form,
          block ? _.cloneDeep(block) : _.cloneDeep(initialFormState)
        );
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(initialFormState);
    this.resetForm();
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.blocks-form {
  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .form-icon {
    margin-right: 8px;
  }
  .form-button-group {
    margin-top: 16px;
  }
}
.ant-input[disabled],
.ant-time-picker-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selection {
  background-color: color(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
